<template>
  <v-menu
    v-model="showPicker"
    v-bind="attributes.menu"
  >
    <template #activator="{ on, attrs }">
      <v-text-field
        :value="displayedDate"
        class="pt-0 input--date-picker"
        :rules="showPicker ? [] : validators"
        :label="field.text"
        :placeholder="field.placeholder"
        v-bind="{...attrs, ...attributes.textField}"
        v-on="on"
        @click.native="showPicker = !showPicker"
      >
        <template #prepend-inner>
          <Icon
            name="date"
            size="small"
            class="mr-2"
          />
        </template>
        <template #append>
          <SwitchDayButtons
            v-model="date"
            :min="minDate"
          />
        </template>
      </v-text-field>
    </template>
    <v-date-picker
      v-model="date"
      v-bind="attributes.datePicker"
      :min="minDate"
      @input="showPicker = false"
    />
  </v-menu>
</template>

<script>
import inputMixin from '../../../mixins/InputMixin.vue'
import get from 'lodash/get'
import { dateStringFormat } from '../../../utils'
import attributes from '../../../const/datePickerAttrributes'
import DateTime from 'luxon/src/datetime'

export default {
  mixins: [inputMixin],
  data: () => ({
    attributes,
    showPicker: false,
    operatingDate: ''
  }),
  computed: {
    date: {
      get () {
        return this.operatingDate
      },
      set (date) {
        this.operatingDate = date
        this.$emit('update', { [this.field.name]: date })
      }
    },
    displayedDate() {
      return this.operatingDate ? dateStringFormat(this.operatingDate) : '-'
    },
    minDate () {
      return this.field.noMinDate ? false : DateTime.utc().toISODate()
    }
  },
  mounted () {
    const date = get(this.data, this.field.value)
    this.operatingDate = date ? new Date(date).toISOString() : ''
  }
}
</script>

<style lang="scss" scoped>
::v-deep .v-label {
  left: -28px !important;
}
</style>
